import { Controller } from "stimulus";
import { post } from "@rails/request.js";

/**
 * This Stimulus component is responsible for managing the display and selection logic
 * of languages in a translation form. It allows for content filtering, language selection management,
 * and controlling the display of text inputs.
 *
 * Targets:
 * - checkbox: Represents the checkboxes used to select form items.
 * - content: Represents the content elements associated with languages.
 * - sourceLang: Represents the input for selecting the source language.
 * - input: Represents the text fields where translations are inserted.
 *
 * Methods:
 * - connect(): Initializes the component and sets the source language from the sourceLang element.
 * - filterContent(e): Filters content based on the clicked element using its ID.
 * - clearSelection(): Resets the checkbox selections and adjusts the display of content.
 * - selectAll(): Selects all checkboxes and displays all content.
 * - showUnTranslatedContent(): Displays only elements with empty text fields.
 * - selectSourceLang(e): Sets the source language when a source input is selected.
 * - setSourceLangInput(inputSourceWrapper): Visually marks the source language.
 * - translate(e): Updates the value of the input with the source and target languages for translation.
 */
export default class extends Controller {
  static targets = [
    "langFilterCheckbox",
    "wrapperInputTranslation",
    "sourceLang",
    "inputTranslation",
  ];

  connect() {
    if (this.sourceLangTarget.length) {
      this.setSourceLangInput(this.sourceLangTarget.value);
    }
  }

  filterContent(e) {
    const content = this.findWrapperInputTranslationForLang(
      e.params.selectLang,
    );
    content?.classList.toggle("d-none");
  }

  clearSelection() {
    this.langFilterCheckboxTargets.forEach((checkbox) => {
      if (
        checkbox.getAttribute(
          "data-form--select-translation-select-lang-param",
        ) !== this.sourceLangTarget.value
      ) {
        checkbox.checked = false;
      }
    });

    this.wrapperInputTranslationTargets.forEach((content) => {
      if (
        content.getAttribute(
          "data-form--select-translation-content-lang-param",
        ) !== this.sourceLangTarget.value
      ) {
        content.classList.add("d-none");
      }
    });
  }

  selectAllFilterLang() {
    this.langFilterCheckboxTargets.forEach((checkbox) => {
      checkbox.checked = true;
    });
    this.wrapperInputTranslationTargets.forEach((content) => {
      content.classList.remove("d-none");
    });
  }

  showUnTranslatedContent() {
    this.wrapperInputTranslationTargets.forEach((content) => {
      const lang = content.getAttribute(
        "data-form--select-translation-content-lang-param",
      );
      const inputValue = content.querySelector(
        '[data-form--select-translation-target="inputTranslation"]',
      ).value;

      const checkbox = this.langFilterCheckboxTargets.find(
        (target) =>
          target.getAttribute(
            "data-form--select-translation-select-lang-param",
          ) == lang,
      );

      if (lang !== this.sourceLangTarget.value) {
        if (inputValue.length === 0) {
          content.classList.remove("d-none");
          checkbox.checked = true;
        } else {
          checkbox.checked = false;
          content.classList.add("d-none");
        }
      }
    });
  }

  selectSourceLang(e) {
    this.selectAllFilterLang();
    this.setSourceLangInput(e.currentTarget.value);
  }

  setSourceLangInput(inputSourceWrapper) {
    if (!inputSourceWrapper) return;

    this.wrapperInputTranslationTargets.forEach((content) => {
      content.classList.remove("form-translation__input-source");
    });

    const content = this.findWrapperInputTranslationForLang(inputSourceWrapper);
    content?.classList.add("form-translation__input-source");
  }

  translate(e) {
    this.createTranslations([e.params.lang]);
  }

  createTranslations(targetLanguages) {
    const sourceLang = this.sourceLangTarget.value;
    post("/translations", {
      body: {
        source_lang: sourceLang,
        source_text: this.findInput(sourceLang).value,
        locales: targetLanguages,
      },
    }).then(async (response) => {
      if (response.ok) {
        const body = await response.json;
        body.translations.forEach((translation) => {
          if (translation.error) {
            this.addInputError(translation);
          } else {
            this.setInput(translation);
          }
        });
      }
    });
  }

  setInput(translation) {
    const input = this.findInput(translation.locale);
    this.clearInputError(input);
    input.value = translation.text;
  }

  clearInputError(input) {
    const inputWrapper = input.closest("div");

    if (inputWrapper.classList.contains("has-error")) {
      inputWrapper.classList.remove("has-error");
      inputWrapper.querySelector("span.help-block").remove();
    }
  }

  addInputError(error) {
    const inputWrapper = this.findInput(error.locale).closest("div");
    if (!inputWrapper.classList.contains("has-error")) {
      inputWrapper.classList.add("has-error");
      const errorMessage = Object.assign(document.createElement("span"), {
        className: "help-block",
        innerHTML: error.error,
      });

      inputWrapper.appendChild(errorMessage);
    }
  }

  findInput(lang) {
    const content = this.findWrapperInputTranslationForLang(lang);
    return content.querySelector(
      '[data-form--select-translation-target="inputTranslation"]',
    );
  }

  findWrapperInputTranslationForLang(lang) {
    return this.wrapperInputTranslationTargets.find(
      (target) =>
        target.getAttribute(
          "data-form--select-translation-content-lang-param",
        ) == lang,
    );
  }
}
