import React, { Component } from 'react';
import ReactDOM from 'react-dom';


export default class Resource extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isSelected: false
    };
  }

  componentDidMount(){
    const isSelected = (this.props.selectedResources.includes(this.props.resource.id)) ? true : false
    this.setState({isSelected: isSelected})
  }

  selectResource = () => {
    const inputResource = document.getElementById(`resource[${this.props.resource.id}]`)
    const value = this.state.isSelected ? false : true
    this.props.selectResource(this.props.resource.id)
    this.setState({isSelected: value})
    if (!inputResource){
      const input = document.createElement("textarea");
      input.setAttribute("value", this.props.resource.text);
      input.setAttribute("name", `resource[${this.props.resource.id}]`);
      input.setAttribute("class", "form-control resources_text_area");
      input.setAttribute("id", `resource[${this.props.resource.id}]`);
      input.innerHTML = this.props.resource.text;
      document.querySelector('.shared_resources_section').appendChild(input);
    } else {
      document.querySelector('.shared_resources_section').removeChild(inputResource);
    }
  }

  render() {
    const resource = this.props.resource;
    const isSelected = this.state.isSelected ? 'selected' : 'not-selected';
    const starKeyOneResource = (resource.key_one == true) ? (
              <i className="icon icon-star" aria-hidden="true"></i>
            ) : (
              <i className="icon icon-star_border" aria-hidden="true"></i>
            );
    return(
      <div className={'resource-card cursor-pointer p-3 mt-3 resource-card--'+isSelected} onClick={this.selectResource}>
        <div className="resource-card-content">
          <h6 class="title-h6 color-new-black pe-5 font-family-mdnichrome">{resource.title}</h6>
          <p>{resource.author}</p>
          {starKeyOneResource}
        </div>
        <div className="d-flex justify-content-end align-items-center resource-card-footer">
          <div className="tag">{resource.format}</div>
          <div className={`text-indent flag flag-${resource.locale}`}>{resource.locale}</div>
        </div>
      </div>
    );
  }
}
